import { BaseElement, html, css, setAnimation, unsafeHTML } from 'Components';
import { Session } from 'Utils';
import App from 'App';

class MenuLarge extends BaseElement {
  static get styles() {
    return css`
      :host {
        text-align:center;
        height:100%;
        width:100%;
        display:flex;
      }
      
      .container {
        display:flex;
        justify-content:flex-start;
        align-items:center;
        height:100%;
        position:relative;
        max-width: 780px;
        min-width: 50vw;
      }
      
      .block_top {
        position:relative;
        cursor:pointer;
        /*
        padding:2px;
        padding-left:5px;
        padding-right:5px;
        */
        padding:0px;
        width: 100%;
        transition: background-color 0.1s;
      }

      .block {
        cursor:pointer;
        padding:2px;
        padding-left:5px;
        padding-right:5px;
        padding:0px;
        min-width:130px;
        height:100%;
        transition: background-color 0.1s;
      }

      .block_top {
        overflow:hidden;
        white-space:nowrap;
      }

      .container.collapsed .block_top {
        /*padding-top:5px;*/
      }

      .collapsed sl-card {
        height: 52px;
        overflow: hidden;
      }

      .block img {
        height:30px;
      }

      .block_top img {
        height:30px;
      }

      .block .text, .block_top .text {
        overflow:hidden;
        font-size:14px;
        color:white;
        text-align:center;
        margin-bottom: 2px;
      }

      .block_top .text {
        padding-left:5px;
        display:flex;
      }

      .container.collapsed .block_top .text {
        padding-left:0px;
      }

      .block .flex_dynamic,
      .block_top .flex_dynamic {
        display: flex;
        flex-direction: column;
        align-items: center;
        position:relative;
        justify-content: center;
        gap:7px;
        padding-top: 10px;
        margin-top:4px;
      }

      .container.collapsed .block .flex_dynamic {
        padding-top:0px;
        margin-top:0px;
      }

      .container.collapsed .block_top .flex_dynamic {
        padding-top:4px;
        margin-top:0px;
      }


      .block .flex_dynamic {
        flex-direction: column;
        position:relative;
      }

      .border:after, .border:before {
        display:block;
        content: '';
        border-bottom: solid 2px #019fb6;  
        transform: scaleX(0);  
        transition: transform 150ms ease-in-out;
      }

      .border:before {
        position:absolute;
        bottom:0px;
        left:0;
        width:100%;
      }

      .border:hover:after {
        transition-delay:150ms;
      }

      .border:hover:after,
      .flex_dynamic:hover:before {
        transform: scaleX(1);
      }

      .block .flex_horizontal {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7px;
        position:relative;
        height:100%;
      }

      .block a {
        text-decoration:none;
      }

      .container.collapsed .flex_dynamic  {
        flex-direction: column;
        gap:0px;
      }

      .container.collapsed img {
        height:28px;
      }

      sl-card {
        position:absolute;
        width:100vw;
        box-sizing: border-box;
        box-shadow:0px 5px 5px 0px #00000066;
      }

      sl-card::part(base) {
        background-color: #1e5179;
        border:initial;
        border-radius:0px;
        
        width:100% !important;
      }

      sl-card .text {
        color:white;
        padding-right:10px;
      }

      sl-card::part(body) {
        padding:0;
        padding-bottom:4px;
        padding-top:4px;
      }

      .collapsed sl-card::part(body) {
        padding-top:0px;
      }

      .container_top {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top:5px;
      }

      .container_top.sub {
        margin-top:10px;
        gap:30px;
        justify-content: center;
      }

      .divider {
        height:10px;
      }

      .img_container {
        max-width:40px;
        text-align:center;
        overflow:hidden;
      }

      .container.collapsed .img_container {
        height:38px;
      }

      .container.collapsed img {
        margin-top: 3px;
      }

      a-route::part(text) {
        text-decoration:none;
        color:white;
      }
      
      .text a {
        color:white;
        text-decoration:none;
      }

      sl-dropdown {
        width: 100%;
        height:100%;
        align-items: center;
        display: flex !important;
        justify-content: space-evenly;
      }

      sl-dropdown::part(trigger) {
        width: 100%;
      }

      sl-dropdown.right {
        margin-left:auto;
        margin-right:30px;
      }

      sl-dropdown[open] {
        background-color: #1e5179;
      }

      sl-dropdown[selected] {
        background-color: #133753;
      }

      /*
      sl-dropdown[selected] .block_top:after {
        bottom:-1px;
        left:0;
        display:block;
        position:absolute;
        width:100%;
        content: '';
        border-bottom: solid 3px #019fb6;  
      }
      */
      
      sl-button::part(base) {
        background-color:initial;
        padding-inline-end:0;
        border:initial !important;
        outline:initial !important;
      }

      sl-button::part(label) {
        line-height:initial;
        font-size:initial;
        margin:initial;
        padding-left:5px;
        padding-left:5px;
      }

      svg {
        float:right;
        margin-left:4px;
      }

      m-icon {
        font-size:30px;
        color:white;
        margin-top:2px;
      }

      .incidents {
        /*background-color: #9c0000;*/
        background: linear-gradient(to top, #770000, red);
        font-weight: bold;
      }

      /*
      @media (max-width: 1150px) {
        .container_top {
          flex-direction:column;
        }

        
        sl-card {
          position:initial;
          width:initial;
        }
      }
      */

      .force13 {
        font-size:13px !important;
      }

      @media (max-width: 670px) {
        :host {
          display:none;
        }
      }


      @media (max-width: 1150px) {
        .block_top {
          /*min-width:initial;*/
        }

        .container {
          gap:5px;
        }

        /*
        .block_top .text {
          display:none;
        }

        .block_top img {
          height: 46px;
        }
        */
        
      }
      
    `;
  }

  static get properties() {
    return {
      menu: { type: Object },
    }
  }

  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.onLocationChanged = this.onLocationChanged.bind(this);
    this.caret = '<svg width="15" viewBox="0 0 24 24" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" part="svg"><polyline points="6 9 12 15 18 9"></polyline></svg>';
  }


  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('language-changed', this.onLanguageChanged);
    window.addEventListener('vaadin-router-location-changed', this.onLocationChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-changed', this.onLanguageChanged);
    window.removeEventListener('vaadin-router-location-changed', this.onLocationChanged);
  }

  firstUpdated() {
    this.container = this.container || this.shadowRoot.querySelector('.container');
    this.dropdown = this.dropdown || this.shadowRoot.querySelector('sl-dropdown');
    this.container.addEventListener('click', this.handleClick);
    this.setupAnimations();
  }

  setupAnimations() {
    // try to prevent flickering effect failed ...
    // return;
    const dialogs = this.shadowRoot.querySelectorAll('sl-dropdown');
    for (const dialog of dialogs) {
      setAnimation(dialog, 'dropdown.show', null);
      setAnimation(dialog, 'dropdown.hide', null);
    }
  }

  onLanguageChanged() {
    this.requestUpdate();
  }

  onLocationChanged() {
    console.log('onLocationChanged');
    this.requestUpdate();
  }

  handleClick(event) {
    let target = event.target;
    const allowed = ['a', 'a-route'];
    while (target != this.container) {
      if (allowed.includes(target.tagName.toLowerCase())) {
        const dropdown = target.parentNode?.parentNode?.parentNode?.parentNode;
        if (dropdown && dropdown.tagName.toLowerCase() === 'sl-dropdown') dropdown.hide();
        break;
      }
      
      target = target.parentNode;
    }
  }

  handleDropdownClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  isSelected(item) {
    if (!item.url) return false;
    console.log(item.url, document.location.pathname);
    if (document.location.pathname.startsWith(item.url)) return true;
    return false;
  }

  render() {
    if (!this.menu) return;
    return html`
      <div class="container collapsed">
        ${
          Object.keys(this.menu).map(key => {

            const menu_item = this.menu[key];

            if (menu_item.grants) {
              if (!Session.hasGrants(menu_item.grants)) {
                return;
              }
            }

            const icon = menu_item.icon 
              ? `<img src="assets/img/${menu_item.icon}"/>` 
              : menu_item.micon 
                ? `<m-icon name="${menu_item.micon}"></m-icon>`:
                '';
            const caret = menu_item.submenu ? this.caret : '';
            const title = menu_item.title_long[App.config.language];
              

            let submenu = '';
            if (menu_item.submenu) {
              Object.keys(menu_item.submenu).map(subkey => {
                const submenu_item = menu_item.submenu[subkey];
                const submenu_icon = submenu_item.icon 
                  ? `<img src="assets/img/${submenu_item.icon}"/>` 
                  : submenu_item.micon
                    ? `<m-icon name="${submenu_item.micon}"></m-icon>`
                    : '';
                    
                const submenu_title = submenu_item.title_long[App.config.language];
                const url = submenu_item.url || '';
                const target = submenu_item.target || '';
                const tag = url.startsWith('/') ? 'a-route' : 'a';

                submenu+= `
                  <div class="block border">
                    <${tag} href="${url}" target="${target}">
                      <div class="flex_horizontal">
                        <div class="img_container">${submenu_icon}</div>
                        <div class="text force13">${submenu_title}</div>
                      </div>
                    </${tag}>
                  </div>
                `;
              });

              submenu = submenu ? `<sl-card><div class="container_top sub">${submenu}</div></sl-card>` : '';

              return unsafeHTML(`
              <sl-dropdown skidding="-300000"
                @click="${this.handleDropdownClick}"
                ${menu_item.float ? `float="${menu_item.float}"` : ''}
                ${this.isSelected(menu_item) ? 'selected' : ''}
              >
                <div slot="trigger">
                  <div class="block_top">
                    <div class="flex_dynamic border ${menu_item.css || ''}">
                      <div class="img_container">${icon}</div>
                      <div class="text">
                        <span>${title}</span>
                        <!--<span>${caret}</span>-->
                      </div>
                    </div>
                  </div>
                </div>
                ${submenu}
              </sl-dropdown>
            `);

            } else {
              const url = menu_item.url || '';
              const target = menu_item.target || '';
              const tag = url.startsWith('/') ? 'a-route' : 'a';

              return unsafeHTML(`
              <sl-dropdown
                skidding="-300000"
                @click="${this.handleDropdownClick}"
                ${menu_item.float ? `class="${menu_item.float}"` : ''}
                ${this.isSelected(menu_item) ? 'selected' : ''}
              >
                <div slot="trigger">
                  <div class="block_top">
                    <${tag} href="${url}" target="${target}">
                      <div class="flex_dynamic ${menu_item.css || ''}">
                        <div class="img_container">${icon}</div>
                        <div class="text">
                          <span>${title}</span>
                          <!--<span>${caret}</span>-->
                        </div>
                      </div>
                    </${tag}>
                  </div>
                </div>
              </sl-dropdown>
            `);
            }
          })
        }
      </div>`;
  }
}

customElements.define('header-menu-large', MenuLarge);
