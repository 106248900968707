import { BaseElement, html, css } from 'Components';
import { Router } from 'Router';
import { Session } from 'Utils';
import App from 'App';

class MenuShort extends BaseElement {
  static get styles() {
    return css`
      :host {
        max-height:45px;
      }

      m-icon {
        font-size:25px;
        color:white;
        /*
        background: linear-gradient(60deg,#1d6393, #1fbbeb);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        */
      }

      m-icon[name="menu"] {
        color: white;
        background:initial;
        -webkit-background-clip: initial;
        -webkit-text-fill-color: initial;
        cursor: pointer;
        font-size: 40px;
        margin-top:2px;
      }

      sl-card {
        margin-top:-7px;
        border-radius:0px;
      }
      sl-card::part(base) {
        border:0px;
        border-radius:0px;
        min-width: 100vw;
        padding-left:0px;
        padding-right:10px;
        padding-top:5px;
        padding-bottom:5px;
        background-color: var(--nsys-blue5);
      }

      sl-card::part(body) {
        border-radius:0px;
        overflow: hidden;
        color:white;
        padding:0px;
        font-size:0.9em;
      }

      a {
        color:white;
        text-decoration:none;
      }

      .menu {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .menu-item {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        line-height: 35px;
        padding-left:10px;
      }

      .menu-item:hover {
        background-color: var(--nsys-blue4);
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap:7px;
      }

      .title .icon {
        height:30px;
        min-width:25px;
        text-align:center;
        display:flex;
        align-items:center;
        justify-content:left;
      }

      .title img {
        height:20px;
      }

      .caret {
        display: flex;
        align-items: center;
      }

      .back-caret {
        transform: rotate(180deg);
        display:block;
      }

      .back-title {
        display:block;
      }

      .submenu {
        display: none;
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        left: 100%;
        top: 0;
      }

      .submenu.open {
        display: block;
      }

      .submenu.close {
        display: block;
      }

      .back-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height:0px;
        padding-top:8px;
        padding-left:20px;
        padding-bottom:10px;
        gap:4px;
      }

      .back-button:hover {
        background-color: var(--nsys-blue4);
      }


      @media (min-width: 670px) {
        :host {
          display:none;
        }
        
      }
    `;
  }

  static get properties() {
    return {
      menu: { type: Object },
    };
  }

  constructor() {
    super();
    this.caret = html`<svg width="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>`;
    this.activeMenu = this.menus;
    this.previousMenuStack = [];
    this.activeMenuTitle = null; // Store the title of the active menu
  }

  handleMenuClick(ev, item) {
    if (item.submenu) {
      this.previousMenuStack.push(this.activeMenu);
      this.activeMenuTitle = item.title_long[App.config.language];
      this.activeMenu = item.submenu;
      this.requestUpdate();
    }
  }

  handleLinkClick(ev, item) {
    this.shadowRoot.querySelector('sl-dropdown').hide();
    if (item.url.startsWith('http')) {
      window.open(item.url, item.target);
    } else {
      window.scrollTo(0, 0);
      Router.go(item.url);
    }
  }

  handleBackClick() {
    const prevMenu = this.previousMenuStack.pop();
    const submenu = this.shadowRoot.querySelector('.submenu');
    if (submenu) {
      submenu.classList.add('close');
      setTimeout(() => {
        this.activeMenu = prevMenu;
        this.requestUpdate();
      }, 300); // Duration of slideOut animation
    } else {
      this.activeMenu = prevMenu;
      this.requestUpdate();
    }
  }

  renderMenu(items) {
    return html`
      <ul class="menu">
        ${Object.keys(items).map(key => {
          const item = items[key];
          const icon = item.icon ? html`<img src="assets/img/${item.icon}"/>` : html`<m-icon name="${item.micon}"></m-icon>`;

          if (item.grants) {
            if (!Session.hasGrants(item.grants)) {
              return;
            }
          }

          return html`
            <li class="menu-item" @click="${item.submenu 
              ? ev => this.handleMenuClick(ev, item)
              : ev => this.handleLinkClick(ev, item)
            }">
              ${item.submenu ? html`
                <div class="title"><div class="icon">${icon}</div><div>${item.title_long[App.config.language]}</div></div>
                <div class="caret">${this.caret}</div>
                <ul class="submenu">
                  ${this.renderMenu(item.submenu)}
                </ul>
              ` : html`
                <div class="title"><div class="icon">${icon}</div><div>${item.title_long[App.config.language]}</div></div>
                <div class="caret">&nbsp;</div>
              `}
            </li>
          `;
        })}
      </ul>
    `;
  }

  render() {
    return html`
      <sl-dropdown>
        <div slot="trigger"><m-icon name="menu"></m-icon></div>
        <sl-card>
          ${this.previousMenuStack.length > 0 ? html`
            <span class="back-button" @click="${this.handleBackClick}">
              <span class="back-caret">${this.caret}</span><span class="back-title">${this.activeMenuTitle}</span>
            </span>
          ` : ''}
          ${this.renderMenu(this.activeMenu || this.menu)}
        </sl-card>
      </sl-dropdown>
    `;
  }
}

customElements.define('header-menu-short', MenuShort);
